import React from "react"
import Container from "@ecom/ui/components/Container"
import * as styles from "./stepsTitle.module.scss"

export default function StepsTitle() {
  return (
    <Container>
      <div className={styles.container}>
        <h1 className={styles.title}>Программа улучшения кредитной истории!</h1>
        <p className={styles.desc}>
          Подключите один из Пакетов Услуг. Каждый из них даёт возможность улучшения кредитной
          истории и дополнительно содержит программу защиты жизни, здоровья или имущества сроком на
          1 год.
          <br />
        </p>
      </div>
    </Container>
  )
}
