import React from "react"
import Container from "@ecom/ui/components/Container"
import clsx from "clsx"

import * as styles from "./FourthStep.module.scss"

export default function FourthStep() {
  return (
    <Container>
      <div className={styles.text_container}>
        <div className={styles.titleContainerFinal}>
          <h3 className={clsx(styles.final, styles.titleInFinalContainer)}>
            Поздравляем! Ваша кредитная история восстановлена.
          </h3>
        </div>
        <p className={styles.muted_text}>
          * Пакеты услуг: вариант №1 «С семейной защитой максимум», вариант №2 «Всё включено»,
          вариант №3 «Умная защита», - активируются при оплате их стоимости собственными средствами
          или за счёт кредитных средств, предоставленных любым банком.
          <br />
          ** В зависимости от выбранной программы после улучшения кредитной истории Вам доступны
          различные лимиты кредитования.
        </p>
        <p className={styles.text}>
          Банк оставляет за собой право отказать в получении кредита по причинам не связанным с
          кредитной историей
        </p>
        <h3 className={styles.final}>Улучшайте кредитную историю в Совкомбанке!</h3>
      </div>
    </Container>
  )
}
